import React from 'react'
import Layout from "../components/layout";
import NavStatic from "../containers/navStatic/NavStatic";
import Footer from "../components/footer/Footer";
import { Link } from "gatsby"

const ComingSoon = () => {
    return (
        <Layout>
            <NavStatic  />
            <div style={{height:'75vh'}}>
                <h1 style={{textAlign:'center'}} >Coming Soon to Shopify</h1> 

            </div>
         
          <Footer />
        </Layout>
    )
}

export default ComingSoon
